import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

import "../style/normalize.css"
import "../style/all.scss"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const topics = data.allMarkdownRemark.distinct
    let postCounter = 0

    return (
      <Layout title={siteTitle}>
        <SEO title="Blog" keywords={[`blog`, `paul price`, `darkport`, ...topics]} />

        <header className="page-head">
          <h2 className="page-head-title">Hello! I'm Paul Price.</h2>
          <h3 className="page-head-subtitle">I make stuff, break stuff, and protect stuff from getting hacked.</h3>
        </header>

        <div className="post-feed">
          {posts.map(({ node }) => {
            postCounter++
            return (
              <PostCard
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
      </Layout>
    )
  }
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { visible: { ne: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      distinct(field: frontmatter___topics)
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            topics
            external
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex props data={data} {...props} />
    )}
  />
)
